var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ag-grid-vue", {
    staticClass: "ag-grid ag-theme-balham",
    attrs: {
      id: "active-project-list",
      gridOptions: _vm.gridOptions,
      columnDefs: _vm.columnDefs,
      context: _vm.context,
      defaultColDef: _vm.defaultColDef,
      pagination: "",
      paginationPageSize: 1000,
      paginationPageSizeSelector: false,
      rowData: _vm.rowData,
      rowModelType: "serverSide",
      overlayNoRowsTemplate: _vm.overlayNoRowsTemplate,
      sideBar: false,
      suppressDragLeaveHidesColumns: "",
      suppressCellFocus: "",
      suppressScrollOnNewData: "",
      suppressContextMenu: "",
      suppressMultiSort: "",
    },
    on: { "grid-ready": _vm.onGridReady },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }